.main-container {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: 850px;
}

.title {
  font-size: 32px;
  font-weight: 600;
  padding: 1rem 2rem;
  color: #7f8c2d;
}

.logo {
  height: 64px;
  position: relative;
  margin-right: -17px;
  top: 6px;
}

.info {
  padding: 1rem 2rem;
  max-width: 720px;
}

.activity {
  padding: 2rem;
  margin: 1rem 0;
  width: 90%;
  box-sizing: border-box;
  min-height: 1px;
  background: #f4f4f4;
  border-radius: 6px;
  margin: 1rem;
  position: relative;

  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.activity-thumbnail {
  width: 32vw;
  max-width: 170px;
  margin-right: 3px;
  cursor: pointer;
}

.camera-icon {
  display: inline-block;
  height: 24px;
  position: relative;
  margin-right: 10px;
}

.activity-title {
  position: relative;
  top: -4px;
  font-size: 20px;
  font-weight: 700;
  padding-bottom: 0.2rem;
  color: #d80027;
}

.activity-description {
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 1.5rem;
}

.activity-new {
  position: relative;
  top: -4px;
  background-color: #315bb9;
  color: white;
  border-radius: 3px;
  padding: 3px 6px;
  margin: 0 10px;
  font-size: 10px;
}

.footer {
  text-align: center;
  font-size: 12px;
  margin: 20px 30px;
}
